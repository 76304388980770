import Button from "@material-ui/core/Button"
import { graphql, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import { Parallax } from "react-parallax"
import ContactForm from "../components/contact"
import Layout from "../components/layout"
import { Projects } from "../components/projects"
import SEO from "../components/seo"

const scrollToRef = ref =>
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: "smooth",
  })
const IndexPage = () => {
  const { bgImage } = useStaticQuery(graphql`
    query {
      bgImage: file(name: { eq: "rundkbg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            src
          }
        }
      }
    }
  `)
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  const myHr = null //<hr style={{ width: "80vw", margin: "0 auto" }} />
  return (
    <Layout>
      <SEO title="Home" />
      <Parallax
        blur={0}
        bgImage={`${bgImage.childImageSharp.fluid.src}`}
        bgImageAlt="heroimage"
        strength={300}
        bgImageStyle={{ objectFit: "cover", maxWidth: "1920px" }}
        bgClassName="filterbrightness"
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            backgroundPosition: "center",
            minHeight: "450px",
            justifyContent: "center",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Kaufen ist günstiger als mieten!
          </h2>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: "#d3c7a1" }}
            onClick={executeScroll}
          >
            Nehmen Sie jetzt kontakt auf!
          </Button>
        </div>{" "}
      </Parallax>

      {myHr}
      <div
        style={{
          display: "flex",
          maxWidth: "960px",
          margin: "20px auto",
          flexDirection: "column",
          padding: "1.45rem 1.0875rem",
        }}
      >
        <h2 style={{ alignSelf: "center", textAlign: "center" }}>
          Aktuelle Projekte
        </h2>
        <p style={{ textAlign: "center" }}>
          Starten Sie jetzt mit den eigenen 4 Wänden. Wir sind eine
          Wohnungsbaugesellschaft mit dem Fokus auf Weiterentwicklung von
          Bestandsimmobilien. Neubauverdächtige Wohnträume zu erschwinglichen
          Preisen. Profitieren Sie von unserer langjährigen Erfahrung gepaart
          mit einem konsequenten Qualitätsanspruch.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <Tabs /> */}
          <Projects />
        </div>
      </div>
      {myHr}
      <div
        ref={myRef}
        style={{
          display: "flex",
          maxWidth: "460px",
          margin: "40px auto",
          flexDirection: "column",
        }}
      >
        <ContactForm />
      </div>
      {myHr}
      <div
        style={{
          display: "flex",
          maxWidth: "960px",
          margin: "40px auto",
          flexDirection: "column",
        }}
      >
        <h3 style={{ alignSelf: "center" }}>Impressum</h3>
        <div className="impressum">
          <p>
            R&K Wohnungsbaugesellschaft mbH
            <br />
            Bonner Str. 12 <br />
            51379 Leverkusen
          </p>
          <p>
            AG Düsseldorf HRB 75573
            <br />
            Geschäftsführer Klaus Kempe, Dirk Ruenz
            <br />
            Telefon <a href="tel:020549693904">02054-9693904</a>
          </p>
          <p>
            <a href="mailto:info@rundk-immo.de">info@rundk-immo.de</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
