import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core"
import React, { FunctionComponent } from "react"
import ImageSlider from "./imageslider"

export const Project: FunctionComponent<{ project: any }> = ({ project }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const handleClickOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Card
        key={project.slug}
        style={{
          maxWidth: 350,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onClick={handleClickOpen}
      >
        <CardActionArea>
          <CardMedia
            style={{ height: "140px" }}
            image={project.images[0].fluid.src}
            title={project.images[0].title || project.title}
          />
          <CardContent style={{}}>
            <Typography variant="h5" component="h2">
              {project.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              {project.subtitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              {documentToReactComponents(JSON.parse(project?.description?.raw))}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <Button size="small" color="primary">
            Weitere Infos
          </Button>
        </CardActions>
      </Card>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {project.title} <br /> {project.subtitle}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: "8px",
            }}
          >
            <span className="MuiIconButton-label">
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="span">
            {documentToReactComponents(project.description.json)}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <div style={{ margin: " 12px 24px" }}>
          <ImageSlider
            images={project.images.map(i => {
              return { src: i.fluid.src, title: i.title }
            })}
          />
        </div>
        {project.pdf && (
          <>
            <Divider />
            <DialogActions style={{ padding: " 8px 20px" }}>
              <div className="breakFlexColumn">
                {project.pdf.map((pdf, idx) => {
                  return (
                    <Button
                      onClick={() => {
                        window.open(pdf.file.url)
                      }}
                      variant="outlined"
                      color="primary"
                      key={idx}
                    >
                      {pdf.title}{" "}
                      <svg
                        className="MuiSvgIcon-root jss78"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style={{ fontSize: "1rem", margin: "0 8px" }}
                      >
                        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                      </svg>
                    </Button>
                  )
                })}
              </div>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
