import React from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "./imageslider.css"

function ArrowLeft(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={"arrow arrow--left" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ fill: "gray" }}
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  )
}

function ArrowRight(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={"arrow arrow--right" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ fill: "gray" }}
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  )
}

export default props => {
  const [details, setDetails] = React.useState(null)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const sliderObj = {
    loop: true,
    slides: props.images.length,
    move(s) {
      setDetails(s.details())
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    initial: currentSlide,
  }

  const [sliderRef, slider] = useKeenSlider(sliderObj)

  function positionStyle(idx) {
    if (!details) return {}
    const position = details.positions[idx]
    if (!position) return {}
    const x = details.widthOrHeight * position.distance
    const scale_size = 0.7
    const scale = 1 - (scale_size - scale_size * position.portion)
    return {
      transform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
    }
  }

  return (
    <>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider zoom-out">
          {props.images.map((image, idx) => (
            <>
              <div
                key={idx}
                style={positionStyle(idx)}
                className="keen-slider__slide zoom-out__slide"
              >
                <img
                  src={image.src}
                  alt={image.title}
                  style={{ height: "100%", objectFit: "contain" }}
                />
              </div>
            </>
          ))}
        </div>
        {slider && (
          <>
            <ArrowLeft onClick={e => e.stopPropagation() || slider.prev()} />

            <ArrowRight onClick={e => e.stopPropagation() || slider.next()} />
          </>
        )}
      </div>
      {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map(idx => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlide(idx)
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
