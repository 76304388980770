import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Project } from "./project"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
  grid-gap: 12px;
  width: 100%;
  justify-content: center;
`
export const Projects = () => {
  const {
    projects: { nodes: projects },
  } = useStaticQuery(graphql`
    query allProjects {
      projects: allContentfulProject {
        nodes {
          title
          subtitle
          slug
          description {
            raw
          }
          images {
            fluid {
              src
            }
            title
          }
          pdf {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)

  return (
    <>
      <Grid>
        {projects.map(project => {
          return <Project key={project.slug} project={project} />
        })}
      </Grid>
    </>
  )
}
