import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import SendIcon from "@material-ui/icons/Send"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}))
const ContactForm = () => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const formSubmission = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    fetch(e.target.getAttribute("action"), {
      method: "POST",
      headers: {
        Accept: "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams(formData).toString(),
    }).then(res => {
      if (res) {
        setOpen(true)
      }
    })
  }

  return (
    <>
      <h2 style={{ alignSelf: "center" }}>Kontakt</h2>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 16,
        }}
        name="Kontaktformular"
        data-netlify="true"
        method="POST"
        onSubmit={formSubmission}
      >
        <input type="hidden" name="Kontaktformular" value="Kontaktformular" />
        <TextField
          required
          id="name"
          name="Name"
          label="Name"
          className={classes.margin}
        />
        <TextField
          required
          id="email"
          name="Email"
          label="Email"
          className={classes.margin}
          error={false}
          type="email"
        />
        <TextField
          id="phone"
          label="Telefon"
          name="Telefon"
          className={classes.margin}
          type="tel"
        />

        <Button
          variant="contained"
          endIcon={<SendIcon />}
          className={classes.margin}
          style={{ backgroundColor: "#d3c7a1" }}
          type="submit"
        >
          Abschicken
        </Button>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Vielen Dank für Ihr Interesse!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wir werden uns in kürze mit Ihnen in Verbindung setzen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ContactForm
